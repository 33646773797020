<template>
  <div class="top-0 flex 2xl:justify-center">
    <div class="w-80 bg-white mr-2">
      <ProductTable
        class="w-full"
        :is-loading="isLoading"
        :current-line-hovered="currentLineHovered"
        :product-previsions="filteredProductPrevisions"
        @update-family-search="handleFamilySearchChange"
        @update-product-name-search="handleProductNameSearchChange"
        @line-hover="handleHover"
      />
    </div>
    <div class="w-96 flex-1 bg-white mx-2">
      <LastWeekTable
        class="w-full"
        :current-line-hovered="currentLineHovered"
        :product-previsions="filteredProductPrevisions"
        :is-loading="isLoading"
        @line-hover="handleHover"
      />
    </div>
    <div class="w-80 2xl:flex-initial bg-white mx-2">
      <TodayPrevisionsTable
        class="w-full"
        :current-line-hovered="currentLineHovered"
        :product-previsions="filteredProductPrevisions"
        :disable-prevision-table="disablePrevisionTable"
        :is-loading="isLoading"
        @line-hover="handleHover"
        @prevision-change="handlePrevisionChange"
      />
    </div>
    <div class="w-96 flex-1 bg-white ml-2">
      <MiddayPrevisionsTable
        class="w-full"
        :current-line-hovered="currentLineHovered"
        :product-previsions="filteredProductPrevisions"
        :disable-midday-table="disableMiddayTable"
        :is-loading="isLoading"
        @line-hover="handleHover"
        @morning-sales-change="handleMorningSalesChange"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import LastWeekTable from './ProductPrevisions/LastWeekTable.vue';
import TodayPrevisionsTable from './ProductPrevisions/TodayPrevisionsTable.vue';
import ProductTable from './ProductPrevisions/ProductTable.vue';
import MiddayPrevisionsTable from './ProductPrevisions/MiddayPrevisionsTable.vue';

export default Vue.extend({
  name: 'ProductPrevisionsDataGroup',

  components: {
    LastWeekTable, TodayPrevisionsTable, ProductTable, MiddayPrevisionsTable,
  },

  props: {
    productPrevisions: { type: Array, default: () => ([]) },
    disablePrevisionTable: { type: Boolean, required: true },
    disablePrevisionValidation: { type: Boolean, required: true },
    disableMiddayTable: { type: Boolean, required: true },
  },

  data() {
    return {
      currentLineHovered: 0,
      search: {
        family: '',
        productName: '',
      },
    };
  },

  computed: {
    isLoading() {
      return this.productPrevisions.length === 0;
    },
    filteredProductPrevisions() {
      return this.productPrevisions.filter((product) => {
        const family = product.family.toLowerCase();
        const productName = product.productName.toLowerCase();
        const searchFamily = this.search.family.toLowerCase().trim();
        const searchProductName = this.search.productName.toLowerCase().trim();
        if (!searchFamily && !searchProductName) return true;

        return family.includes(searchFamily) && productName.includes(searchProductName);
      });
    },
  },

  methods: {
    handleFamilySearchChange(value) {
      this.search.family = value;
    },
    handleProductNameSearchChange(value) {
      this.search.productName = value;
    },
    handleAmbitionChange(value) {
      console.log('EVENT CATCH: AMBITION CHANGE', value);
    },
    handleHover(i) {
      this.currentLineHovered = i;
    },
    async handlePrevisionChange() {
      this.$emit('prevision-change');
    },
    async handleMorningSalesChange() {
      this.$emit('morning-sales-change');
    },
  },

});
</script>
