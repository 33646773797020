<template>
  <div class="flex flex-col 2xl:items-center">
    <div class="w-full flex justify-between items-center bg-gray-300">
      <h2
        class="sticky left-0 cursor-pointer text-base font-semibold p-2"
        @click="collapseStorePrevision = !collapseStorePrevision"
      >
        <InformationCircleIcon size="1.4x" class="inline-block mr-2" />
        <span>Informations générales</span>
      </h2>
      <ChevronDownIcon
        size="2x"
        class="sticky right-0 mr-2 cursor-pointer transition-transform"
        :class="{'-rotate-90': collapseStorePrevision}"
        @click="collapseStorePrevision = !collapseStorePrevision"
      />
    </div>

    <div
      class="w-full flex overflow-hidden transition-height"
      :class="{ 'h-0': collapseStorePrevision, 'h-[16.3rem]': !collapseStorePrevision }"
    >
      <div class="w-80 bg-gray-50 mr-2">
        <EmptyHeader
          class="w-full h-44"
          v-if="storePrevision"
          @onGenerated="handleRegenerated"
          :prevision-date="storePrevision.previsionDate"
          :store-id="storePrevision.storeId"
        />
      </div>

      <div class="w-96 flex-1 bg-gray-50 mx-2">
        <LastWeekHeader
          :weather-loading="weatherLoading"
          class="w-full"
          :weather-old="weatherList.old"
          :store-prevision="storePrevision" />
      </div>

      <div class="relative w-80 2xl:flex-initial bg-gray-50 mx-2">
        <div
          v-if="$store.getters['storePrevisions/isSendingExpectedRevenue']"
          class="z-50 absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-70"
        >
          <ClockIcon size="4x" class="inline-block animate-spin" />
        </div>
        <TodayPrevisionsHeader
          :weather-loading="weatherLoading"
          class="w-full"
          :weather-new="weatherList.new"
          :store-prevision="storePrevision"
          :disable-prevision-validation="disablePrevisionValidation"
          @expected-revenue-change="handleExpectedRevenueChange"
        />
      </div>

      <div class="w-96 flex-1 bg-gray-50 ml-2">
        <MiddayCheckupHeader
          class="w-full"
          :store-prevision="storePrevision"
          :refresh-loading="refreshLoading"
          :refresh-error-messages="refreshErrorMessages"
          :disable-midday-revenue="disableMiddayRevenue"
          @morning-revenue-change="handleMorningRevenueChange"
          @refresh-morning-sales="handleRefreshMorningSales"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ChevronDownIcon, InformationCircleIcon, ClockIcon } from '@vue-hero-icons/outline';
import LastWeekHeader from './StorePrevisions/LastWeekHeader.vue';
import TodayPrevisionsHeader from './StorePrevisions/TodayPrevisionsHeader.vue';
import MiddayCheckupHeader from './StorePrevisions/MiddayCheckupHeader.vue';
import EmptyHeader from './StorePrevisions/EmptyHeader.vue';

export default Vue.extend({
  name: 'StorePrevisionsDataGroup',

  components: {
    LastWeekHeader,
    TodayPrevisionsHeader,
    MiddayCheckupHeader,
    EmptyHeader,
    ChevronDownIcon,
    InformationCircleIcon,
    ClockIcon,
  },

  props: {
    storePrevision: { type: Object, default: () => null },
    disablePrevisionValidation: { type: Boolean, required: true },
    disableMiddayRevenue: { type: Boolean, required: true },
    refreshLoading: { type: Boolean, required: true },
    weatherList: { type: Object, default: () => null },
    weatherLoading: { type: Boolean, required: true },
    refreshErrorMessages: { type: String, default: () => '', required: false },
  },

  data() {
    return {
      collapseStorePrevision: false,
    };
  },

  methods: {
    async handleRegenerated() {
      this.$emit('previsions-regenerated');
    },
    handleExpectedRevenueChange(value) {
      this.$emit('expected-revenue-change', value);
    },
    handleMorningRevenueChange(value) {
      this.$emit('morning-revenue-change', value);
    },
    handleRefreshMorningSales() {
      this.$emit('refresh-morning-sales');
    },
  },

});
</script>
