<template>
  <div class="relative flex flex-col overflow-hidden bg-gray-50">
    <div class="border-b text-center py-2 bg-gray-200">
      Ventes du {{ selectedDate }}
    </div>
    <div class="store-header-item">
      <div>Matin</div>
      <div>
        {{ lastWeekMorningRevenue }} €
      </div>
    </div>
    <div class="store-header-item">
      <div>Ap. midi</div>
      <div>
        {{ lastWeekAfternoonRevenue }} €
      </div>
    </div>
    <div class="store-header-item">
      <div>Journée</div>
      <div>
        {{ lastWeekRevenue }} €
      </div>
    </div>
    <div class="store-header-item">
      <div>-50% (après 19h)</div>
      <div>
        {{ lastWeekDiscountedRevenue }} €
      </div>
    </div>
    <app-weather-widget
      :weather-loading="weatherLoading"
      :weather="weatherOld"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import Weather from '@/entities/Weather';
import AppWeatherWidget from '@/components/Widgets/WeatherWidget.vue';

dayjs.locale('fr');

export default Vue.extend({
  name: 'LastWeekHeader',

  components: { AppWeatherWidget },

  props: {
    storePrevision: { type: Object, default: null },
    weatherOld: { type: Weather, default: null },
    weatherLoading: { type: Boolean, required: true },
  },

  computed: {
    selectedDate() {
      if (this.$store.getters.selectedDate === '') return ('-');
      const tempDate = dayjs(this.$store.getters.selectedDate).subtract(1, 'week');
      return (dayjs(tempDate).format('dddd DD MMMM YYYY'));
    },
    lastWeekMorningRevenue() {
      return this.storePrevision?.lastWeekMorningRevenue?.toFixed(2) ?? '-';
    },
    lastWeekAfternoonRevenue() {
      return this.storePrevision?.lastWeekAfternoonRevenue?.toFixed(2) ?? '-';
    },
    lastWeekRevenue() {
      return this.storePrevision?.lastWeekRevenue?.toFixed(2) ?? '-';
    },
    lastWeekDiscountedRevenue() {
      return this.storePrevision?.lastWeekDiscountedRevenue?.toFixed(2) ?? '-';
    },
  },
});
</script>
