<template>
  <div class="relative flex flex-col overflow-hidden bg-gray-50">
    <div class="border-b text-center py-2 bg-gray-200">
      Prévisions du {{ selectedDate }}
    </div>

    <div class="store-header-item">
      <div>Ambition CA</div>
      <div class="mr-18">
        <input
          v-model.number="expectedRevenue"
          class="border rounded w-20 text-right"
          :class="{ 'text-gray-500': disablePrevisionValidation }"
          type="number"
          min="1"
          :disabled="disablePrevisionValidation"
        >
        <span> €</span>
      </div>
      <div>
        <button
          v-if="!disablePrevisionValidation"
          class="px-1 rounded bg-green-400 hover:bg-green-500
          disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed"
          :disabled="shouldDisableExpectedRevenueValidationButton"
          @click="sendExpectedRevenueValue"
        >
          <CheckIcon size="1.4x" class="inline-block text-white" />
        </button>
        <button v-else disabled class="px-1 rounded bg-gray-200">
          <CheckIcon size="1.4x" class="inline-block cursor-not-allowed text-gray-400" />
        </button>
      </div>
    </div>

    <div class="store-header-item">
      <div>CA des produits</div>
      <div>{{ reCalculatedRevenue }} €</div>
    </div>
    <div class="weather">
      <app-weather-widget
        :weather-loading="weatherLoading"
        :weather="weatherNew"
      />
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { CheckIcon } from '@vue-hero-icons/outline';
import Weather from '@/entities/Weather';
import AppWeatherWidget from '@/components/Widgets/WeatherWidget.vue';

dayjs.locale('fr');

export default Vue.extend({
  name: 'TodayPrevisionsHeader',

  components: {
    CheckIcon,
    AppWeatherWidget,
  },

  props: {
    storePrevision: { type: Object, default: null },
    disablePrevisionValidation: { type: Boolean, required: true },
    weatherNew: { type: Weather, default: null },
    weatherLoading: { type: Boolean, required: true },
  },

  data() {
    return {
      expectedRevenue: null,
    };
  },

  computed: {
    selectedDate() {
      if (this.$store.getters.selectedDate === '') return '-';
      return dayjs(this.$store.getters.selectedDate).format('dddd DD MMMM YYYY');
    },
    reCalculatedRevenue() {
      const reCalculatedRevenue = this.$store.getters['storePrevisions/reCalculatedRevenue'];
      return Number(reCalculatedRevenue).toFixed(2);
    },
    shouldDisableExpectedRevenueValidationButton() {
      return this.expectedRevenue <= 0 || !this.expectedRevenue;
    },
    urlIconTempMax() {
      return `https://openweathermap.org/img/wn/${this.weatherNew?.weatherIcon}.png`;
    },
  },

  watch: {
    'storePrevision.expectedRevenue': function (newValue) { // eslint-disable-line func-names
      this.expectedRevenue = newValue ?? null;
    },
  },

  methods: {
    sendExpectedRevenueValue() {
      this.storePrevision.expectedRevenue = this.expectedRevenue;
      this.$emit('expected-revenue-change', this.storePrevision.expectedRevenue);
    },
  },
});
</script>
<style scoped>
  .weather{
    margin-top: 72px;
  }
</style>
