<template>
  <div class="flex flex-col bg-gray-50">
    <div class="text-center py-2 bg-gray-300">
      Point de mi-journée
    </div>

    <div v-if="isLoading" class="h-96 flex items-center justify-center text-3xl text-gray-400">
      <ClockIcon size="4x" class="animate-spin" />
    </div>

    <div v-else-if="!productPrevisions.length" class="h-96 flex items-center justify-center text-2xl text-gray-400">
      <pre>Aucune donnée</pre>
    </div>

    <table v-else-if="productPrevisions.length" class="table-fixed product-table w-full">
      <thead class="sticky top-0 h-12 flex-1 items-center text-center py-2 bg-gray-300">
        <tr>
          <th>Ventes<br>du matin</th>
          <th>Ecart<br>s-1</th>
          <th>Besoin</th>
          <th class="whitespace-nowrap">
            Stock<br>(cuit+cru)
          </th>
          <th>Relances</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, i) of productPrevisions"
          :key="i"
          :class="{'bg-gray-200': isCurrentLineIndex(i) }"
          @mouseover="handleLineHover(i)"
        >
          <td class="text-center">
            <input
              :disabled="permissionMidday || disableMiddayTable"
              v-model.number="product.morningSoldQuantity"
              class="product-table-input w-full disabled:text-gray-400"
              type="number"
              :min="0"
              placeholder="unités"
              @input="handleMorningSalesInput(product)"
            >
          </td>
          <td
            class="text-center"
            :class="{'text-gray-400': disableMiddayTable}"
          >
            {{ lastWeekAfternoonSoldDiffString(product) }}
          </td>
          <td
            class="text-center"
            :class="{'text-gray-400': disableMiddayTable}"
          >
            {{ midDayNeedsString(product) }}
          </td>
          <td class="text-center">
            <input
              v-model.number="product.stockQuantity"
              class="product-table-input w-full disabled:text-gray-400"
              type="number"
              :min="0"
              :placeholder="placeholder(product)"
              :disabled="disableMiddayTable"
              @input="handleStockInput(product)"
            >
          </td>
          <td class="text-center">
            <input
              v-model.number="product.relaunchQuantity"
              class="product-table-input w-full disabled:text-gray-400"
              type="number"
              :min="0"
              :placeholder="placeholder(product)"
              :disabled="disableMiddayTable"
              @input="handleRelaunchQuantity(product)"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import { ClockIcon } from '@vue-hero-icons/outline';
import permissionMiddayRefreshService from '@/services/permisson-midday-refresh/permission-midday-refresh.service';

export default Vue.extend({
  name: 'MiddayPrevisionsTable',

  components: { ClockIcon },

  props: {
    productPrevisions: { type: Array, default: () => ([]) },
    currentLineHovered: { type: Number, required: true, default: 0 },
    disableMiddayTable: { type: Boolean, required: true },
    isLoading: { type: Boolean, required: true, default: true },
  },
  computed: {
    shopId() {
      return this.$store.getters['shopAuth/authenticatedShopId'];
    },
    permissionMidday() {
      return permissionMiddayRefreshService.checkIfShopIsAllowedToRefreshMiddayCheckin(this.shopId);
    },
  },
  methods: {
    handleLineHover(lineNumber) {
      this.$emit('line-hover', lineNumber);
    },
    isCurrentLineIndex(lineNumber) {
      return lineNumber === this.currentLineHovered;
    },
    handleMorningSalesInput(product) {
      const actualProduct = product;
      actualProduct.relaunchQuantity = this.calcRelaunchQuantity(product);
      this.$emit('morning-sales-change');
    },
    lastWeekAfternoonSoldDiff(product) {
      if (product.morningSoldQuantity !== 0 && !product.morningSoldQuantity) {
        return '';
      }
      return product.morningSoldQuantity - product.lastWeekMorningSoldQuantity;
    },
    lastWeekAfternoonSoldDiffString(product) {
      const units = this.lastWeekAfternoonSoldDiff(product);
      return product.numberToPlateString(units);
    },
    midDayNeeds(product) {
      if (product.morningSoldQuantity !== 0 && !product.morningSoldQuantity) {
        return '';
      }
      const midDayNeeds = this.lastWeekAfternoonSoldDiff(product) + product.lastWeekAfternoonSoldQuantity;
      return Math.max(0, midDayNeeds);
    },
    midDayNeedsString(product) {
      const needsUnits = this.midDayNeeds(product);
      return product.numberToPlateString(needsUnits);
    },
    handleStockInput(product) {
      const actualProduct = product;
      actualProduct.relaunchQuantity = this.calcRelaunchQuantity(product);
      this.$emit('morning-sales-change');
    },
    // eslint-disable-next-line consistent-return
    calcRelaunchQuantity(product) {
      if (product.stockQuantity === '') {
        return null;
      }
      // TODO : check quantityPerPlate, if quantityPerPlate > 1, convert to plate
      if (product.quantityPerPlate <= 1) {
        const relaunchQuantity = this.midDayNeeds(product) - product.stockQuantity;
        const relaunchQuantityPlate = product.convertToPlateQuantityIfNeeded(relaunchQuantity);
        if (relaunchQuantityPlate <= 0) {
          return null;
        }
        return relaunchQuantityPlate;
      }
      if (product.quantityPerPlate > 1) {
        const midDayNeedsInPlate = product.convertToPlateQuantity(this.midDayNeeds(product));
        const relaunchQuantityPlate = midDayNeedsInPlate - product.stockQuantity;
        if (relaunchQuantityPlate <= 0) {
          return null;
        }
        return relaunchQuantityPlate;
      }
    },
    handleRelaunchQuantity() {
      this.$emit('morning-sales-change');
    },
    placeholder(product) {
      if (product.isPlating()) {
        return 'plaques';
      }
      return '';
    },
  },
});
</script>
