<template>
  <div class="py-4 flex items-center justify-center">
    <button
      class="button-enabled button-disabled bg-blue-500 hover:bg-blue-400"
      :disabled="disableExportButtons"
      @click="handleMainSheetDownload"
    >
      <DocumentDownloadIcon size="1.4x" class="inline-block" />
      <span>Fiche {{ sheetDocumentButtonText }}</span>
    </button>

    <button
      class="button-enabled button-disabled bg-blue-500 hover:bg-blue-400"
      :disabled="disableExportButtons"
      @click="handleRecapSheetDownload"
    >
      <DocumentDownloadIcon size="1.4x" class="inline-block" />
      <span>Récapitulatif</span>
    </button>

    <button
      class="button-enabled button-disabled bg-blue-500 hover:bg-blue-400"
      :disabled="disableExportButtons"
      @click="handleSupportStockSheetDownload"
    >
      <DocumentDownloadIcon size="1.4x" class="inline-block" />
      <span>Support stock</span>
    </button>

    <div class="lg:w-12" />

    <button
      v-show="!isPrevisionValidated"
      class="button-enabled button-disabled w-40 bg-green-500 hover:bg-green-400"
      :disabled="disablePrevisionValidation"
      @click="handleValidatePrevision"
    >
      <CheckCircleIcon size="1.4x" class="inline-block" />
      <span>Valider prévisions</span>
    </button>

    <button
      v-show="isPrevisionValidated"
      class="button-enabled button-disabled w-40 bg-yellow-500 hover:bg-yellow-400"
      :disabled="disablePrevisionValidation"
      @click="handleEditPrevision"
    >
      <PencilAltIcon size="1.4x" class="inline-block" />
      <span>Modifier prévisions</span>
    </button>

    <button
      v-show="!isMiddayValidated"
      class="button-enabled button-disabled bg-green-500 hover:bg-green-400"
      :disabled="disableMiddayValidation"
      @click="handleValidateMidday"
    >
      <CheckCircleIcon size="1.4x" class="inline-block" />
      <span>Valider relances</span>
    </button>
    <button
      v-show="isMiddayValidated"
      class="button-enabled button-disabled w-40 bg-yellow-500 hover:bg-yellow-400"
      :disabled="disableMiddayValidation"
      @click="handleEditMidday"
    >
      <PencilAltIcon size="1.4x" class="inline-block" />
      <span>Modifier relances</span>
    </button>
  </div>
</template>

<script>
import Vue from 'vue';
import { DocumentDownloadIcon, CheckCircleIcon, PencilAltIcon } from '@vue-hero-icons/outline';

export default Vue.extend({
  name: 'ProductButtons',

  components: {
    DocumentDownloadIcon,
    CheckCircleIcon,
    PencilAltIcon,
  },

  props: {
    disableExportButtons: { type: Boolean, required: true },
    disablePrevisionValidation: { type: Boolean, required: true },
    disableMiddayValidation: { type: Boolean, required: true },
    isPrevisionValidated: { type: Boolean, required: true },
    isMiddayValidated: { type: Boolean, required: true },
  },
  computed: {
    isPreparateurPage() {
      return this.$route.name === 'PreparateurPage';
    },
    isBoulangerPage() {
      return this.$route.name === 'BoulangerPage';
    },
    sheetDocumentButtonText() {
      if (this.isPreparateurPage) return 'préparateur';
      if (this.isBoulangerPage) return 'boulanger';
      return '';
    },
  },
  methods: {
    handleValidatePrevision() {
      this.$emit('prevision-validation');
    },
    handleEditPrevision() {
      this.$emit('prevision-edit');
    },
    handleValidateMidday() {
      this.$emit('midday-validation');
    },
    handleEditMidday() {
      this.$emit('midday-edit');
    },
    handleMainSheetDownload() {
      if (this.isPreparateurPage) {
        this.$emit('download-preparateur-main-sheet');
      }
      if (this.isBoulangerPage) {
        this.$emit('download-boulanger-main-sheet');
      }
    },
    handleRecapSheetDownload() {
      if (this.isPreparateurPage) {
        this.$emit('download-preparateur-recap-sheet');
      }
      if (this.isBoulangerPage) {
        this.$emit('download-boulanger-recap-sheet');
      }
    },
    handleSupportStockSheetDownload() {
      if (this.isPreparateurPage) {
        this.$emit('download-preparateur-support-stock-sheet');
      }
      if (this.isBoulangerPage) {
        this.$emit('download-boulanger-support-stock-sheet');
      }
    },
  },

});
</script>

<style scoped>
.button-enabled {
  @apply h-10 px-2 mx-2 rounded text-white;
}
.button-disabled {
  @apply disabled:text-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed;
}
</style>
