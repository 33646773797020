<template>
  <div class="h-full flex flex-col bg-gray-50">
    <div class="text-center py-2 bg-gray-200">
      <div><i class="el-icon-info text-gray-500"/> Un problème sur les données de vente ?</div>
    </div>
    <div class="store-header-item text-center">
      <el-popconfirm
        class="w-full"
        v-if="!loading"
        confirm-button-text='Confirmer'
        cancel-button-text='Annuler'
        icon="el-icon-info"
        icon-color="red"
        title="Ceci écrasera toutes les prévisions du jour. Êtes-vous sûr ?"
        @confirm="regeneratePrevisions"
      >
        <el-button
          slot="reference"
          class="w-full"
          icon="el-icon-refresh"
          size="mini"
        >
          Regénérer les données de vente
        </el-button>
      </el-popconfirm>
      <el-button
        v-if="loading"
        class="w-full"
        icon="el-icon-loading"
        size="mini"
      >
        Génération en cours...
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import services from '@/services/server';

export default Vue.extend({
  name: 'EmptyHeader',
  props: {
    storeId: { type: String, required: false },
    previsionDate: { type: String, required: false },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async regeneratePrevisions() {
      try {
        this.loading = true;
        await new Promise((resolve) => setTimeout(resolve, 3000));
        if (!this.storeId || !this.previsionDate) {
          throw new Error('MISSING_DATE_OR_ID');
        }
        await services.storePrevisionService.regenerateStoreAndProductPrevisions(this.storeId, this.previsionDate);
        this.$message.success('Prévisions générées !');
        this.$emit('onGenerated');
      } catch (e) {
        console.log('Error while regenerating prevision:', e);
        switch (e.message) {
          case 'REGENERATE_ERROR: SALES_REPORTER_ERROR': {
            this.$message.error('Impossible de récupérer les données de vente du prestataire.');
            break;
          }
          case ('REGENERATE_ERROR: FORBIDDEN_TIME_PERIOD'): {
            this.$message.error('Les données avant j+2 ne peuvent pas être regénérées.');
            break;
          }
          case ('MISSING_DATE_OR_ID'): {
            this.$message.error('Une erreur est survenue : date ou id magasin manquant');
            break;
          }
          default: {
            this.$message.error('Une erreur inconnue est survenue');
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
