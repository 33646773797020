<template>
  <div class="flex flex-col bg-gray-50">
    <div class="text-center bg-gray-300">
      &nbsp;
    </div>

    <div v-if="isLoading" class="h-96 flex items-center justify-center text-3xl text-gray-400">
      <ClockIcon size="4x" class="animate-spin" />
    </div>

    <table v-else class="table-fixed product-table w-full">
      <thead class="sticky border-0 top-0 h-12 text-center py-2 bg-gray-300">
        <tr>
          <th class="w-20 px-3">
            Famille
            <el-input
              v-model="familySearch"
              size="mini"
              placeholder="Rechercher par famille"
              class="my-2"
              clearable
            />
          </th>
          <th class="w-40 border-0 px-3">
            Produit
            <el-input
              v-model="productNameSearch"
              size="mini"
              placeholder="Rechercher par nom produit"
              class="my-2"
              clearable
            />
          </th>
        </tr>
      </thead>
      <div
        v-if="!productPrevisions.length"
        class="text-2xl text-gray-400 flex items-center justify-center
        h-80
      w-80 bg-red text-center"
      >
        Aucune donnée
      </div>
      <tbody v-else>
        <tr
          v-for="(product, i) of productPrevisions"
          :key="product.id"
          :class="{'bg-gray-200': isCurrentLineIndex(i) }"
          @mouseover="handleLineHover(i)"
        >
          <td class="px-1 whitespace-nowrap overflow-hidden overflow-ellipsis" :title="product.family">
            {{ product.family }}
          </td>
          <td
            class="pr-1 whitespace-nowrap overflow-hidden overflow-ellipsis"
            :title="productNameTitle(product)"
            v-html="productName(product)"
          />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import { ClockIcon } from '@vue-hero-icons/outline';

export default Vue.extend({
  name: 'ProductTable',

  components: { ClockIcon },

  props: {
    productPrevisions: { type: Array, default: () => ([]) },
    currentLineHovered: { type: Number, required: true, default: 0 },
    isLoading: { type: Boolean, default: true },
  },

  data() {
    return {
      familySearch: '',
      productNameSearch: '',
    };
  },

  watch: {
    familySearch(value) {
      this.$emit('update-family-search', value);
    },
    productNameSearch(value) {
      this.$emit('update-product-name-search', value);
    },
  },

  methods: {
    handleLineHover(lineNumber) {
      this.$emit('line-hover', lineNumber);
    },
    isCurrentLineIndex(lineNumber) {
      return lineNumber === this.currentLineHovered;
    },
    productName(product) {
      if (product.family !== 'Plaques' && product.quantityPerPlate > 1) {
        return `${product.productName} <span class="font-bold">x${product.quantityPerPlate}</span>`;
      }
      return product.productName;
    },
    productNameTitle(product) {
      if (product.family !== 'Plaques' && product.quantityPerPlate > 1) {
        return `${product.productName} x${product.quantityPerPlate}`;
      }
      return product.productName;
    },
  },
});
</script>
