<template>
  <div class="flex flex-col bg-gray-50">
    <div class="text-center py-2 bg-gray-300">
      Prévisions du {{ selectedDate }}
    </div>
    <div v-if="isLoading" class="h-96 flex items-center justify-center text-3xl text-gray-400">
      <ClockIcon size="4x" class="animate-spin" />
    </div>
    <div v-else-if="!productPrevisions.length" class="h-96 flex items-center justify-center text-2xl text-gray-400">
      <pre>Aucune donnée</pre>
    </div>

    <table v-else-if="productPrevisions.length" class="table-fixed product-table w-full">
      <thead class="z-10 sticky top-0 h-12 flex-1 items-center text-center py-2 bg-gray-300">
        <tr>
          <th class="w-24">
            Qté liée à<br> l'ambition
          </th>
          <th class="w-24">
            Prévisions<br> magasin
          </th>
          <th class="w-24">
            Rayon<br> d'ouverture
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, i) of productPrevisions"
          :key="i"
          :class="{'bg-gray-200': isCurrentLineIndex(i) }"
          @mouseover="handleLineHover(i)"
        >
          <td class="text-center">
            <ClockIcon v-if="isSuggestedQuantityLoading" size="1x" class="inline-block animate-spin" />
            <span v-else :class="{'text-gray-400': disablePrevisionTable}">
              {{ suggestedQuantity(product) }}
            </span>
          </td>
          <td class="text-center">
            <input
              v-model.number="product.plannedQuantity"
              class="product-table-input w-full disabled:text-gray-400"
              type="number"
              :min="0"
              :placeholder="placeholder(product)"
              :disabled="disablePrevisionTable"
              @input="handleProductPlannedAndOpeningQuantities"
            >
          </td>
          <td class="text-center">
            <input
              v-model.number="product.openingQuantity"
              class="product-table-input w-full disabled:text-gray-400"
              type="number"
              :min="0"
              :placeholder="placeholder(product)"
              :disabled="disablePrevisionTable"
              @input="handleProductPlannedAndOpeningQuantities"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import { ClockIcon } from '@vue-hero-icons/outline';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr');

export default Vue.extend({
  name: 'TodayPrevisionsTable',

  components: { ClockIcon },

  props: {
    productPrevisions: { type: Array, default: () => ([]) },
    currentLineHovered: { type: Number, required: true, default: 0 },
    disablePrevisionTable: { type: Boolean, required: true },
    isLoading: { type: Boolean, required: true, default: false },
  },

  data() {
    return {
    };
  },

  computed: {
    selectedDate() {
      if (this.$store.getters.selectedDate === '') return ('-');
      return dayjs(this.$store.getters.selectedDate).format('dddd DD MMMM YYYY');
    },
    isSuggestedQuantityLoading() {
      return this.$store.getters['storePrevisions/isSendingExpectedRevenue'];
    },
  },
  methods: {
    handleLineHover(lineNumber) {
      this.$emit('line-hover', lineNumber);
    },
    isCurrentLineIndex(lineNumber) {
      return lineNumber === this.currentLineHovered;
    },
    suggestedQuantity(product) {
      if (product.suggestedQuantity !== 0 && !product.suggestedQuantity) {
        return '';
      }
      const plates = product.quantityPerPlate > 1 ? product.suggestedQuantity / product.quantityPerPlate : 0;
      const roundedPlates = Math.ceil(plates);
      if (product.family !== 'Plaques' && product.quantityPerPlate > 1) {
        return `${roundedPlates} (${product.suggestedQuantity})`;
      }
      return product.suggestedQuantity;
    },
    placeholder(product) {
      if (product.family === 'Plaques') {
        return 'parts';
      }
      if (product.quantityPerPlate > 1) {
        return 'plaques';
      }
      return '';
    },
    async handleProductPlannedAndOpeningQuantities() {
      this.$emit('prevision-change');
    },
  },
});
</script>
