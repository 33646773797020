<template>
  <div class="h-full flex flex-col bg-gray-50">
    <div class="flex justify-between border-b px-4 py-2 bg-gray-200">
      <div>Point de mi-journée</div>
    </div>
    <div class="store-header-item">
      <div>CA matin</div>
      <div>
        <input
          v-if="!permissionMidday"
          v-model.number="morningRevenue"
          :class="{'text-gray-500': disableMiddayRevenue}"
          :disabled="disableMiddayRevenue"
          :min="0"
          class="border rounded w-20 text-right"
          type="number"
          @change="sendMorningRevenueValue"
        >
        <span v-if="!permissionMidday"> €</span>
      </div>
      <div v-if="permissionMidday">
        {{ morningRevenue }} €
      </div>
    </div>
    <div class="store-header-item">
      <div>
        Écart s -1:
      </div>
      <div>
        {{ lastWeekRevenueDiff }} €
      </div>
    </div>
    <div class="px-8 mt-12  text-center text-red-500">
      <el-button
        v-if="permissionMidday"
        :class="classErrorForButton"
        :disabled="refreshDisabled && selectedDateIsGreaterThanCurrentDate ||
          (disableMiddayRevenue && selectedDateIsGreaterThanCurrentDate)"
        :loading="refreshLoading"
        icon="el-icon-refresh"
        type="primary"
        @click="refreshMorningSales()"
      >
        Actualiser Ventes
      </el-button>
      <!--      <el-button
              :class="classErrorForButton"
              v-if="permissionMidday"
              :loading="refreshLoading"
              icon="el-icon-refresh"
              type="primary"
              @click="refreshMorningSales()">Actualiser Ventes
            </el-button>-->
    </div>
    <span
      v-if="refreshLoading"
      class="refresh-message"
    >Le chargement des données peut prendre du temps, veuillez patienter...</span>
    <span v-if="refreshErrorMessages" class="refresh-error-message">
      {{ refreshErrorMessages }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
import permissionMiddayRefreshService from '@/services/permisson-midday-refresh/permission-midday-refresh.service';

export default Vue.extend({
  name: 'MiddayCheckupHeader',

  components: {},
  props: {
    storePrevision: { type: Object, default: null },
    disableMiddayRevenue: { type: Boolean, required: true },
    refreshLoading: { type: Boolean, required: true },
    refreshErrorMessages: { type: String, default: () => '', required: false },
  },
  data() {
    return {
      morningRevenue: null,
    };
  },
  computed: {
    selectedDateIsGreaterThanCurrentDate() {
      const currentDate = new Date().toISOString().split('T')[0];
      return new Date(this.$store.getters.selectedDate) >= new Date(currentDate);
    },
    classErrorForButton() {
      if (this.refreshErrorMessages !== '') {
        return 'button-error';
      }
      return '';
    },

    lastWeekRevenueDiff() {
      return this.storePrevision?.lastWeekRevenueDiff?.toFixed(2) ?? '-';
    },
    refreshDisabled() {
      /*       if (process.env.VUE_APP_FRONTEND_URL !== 'https://prev-prod-outils-dev.marieblachere.fr') {
        console.log('This is not PROD environment, so we allow refresh midday checkin');
        return false;
      } */
      const timeStartValideInMinutes = (12 * 60) + 40;
      const currentDate = new Date();
      const currentTimeInMinutes = (currentDate.getHours() * 60) + currentDate.getMinutes();
      return currentTimeInMinutes <= timeStartValideInMinutes;
    },
    shopId() {
      return this.$store.getters['shopAuth/authenticatedShopId'];
    },
    permissionMidday() {
      return permissionMiddayRefreshService.checkIfShopIsAllowedToRefreshMiddayCheckin(this.shopId);
    },
  },
  watch: {
    storePrevision(newValue) { // eslint-disable-line func-names
      this.morningRevenue = newValue.morningRevenue ?? null;
    },
  },
  methods: {
    sendMorningRevenueValue() {
      // this.storePrevision.morningRevenue = this.morningRevenue;
      this.$emit('morning-revenue-change', this.morningRevenue);
    },
    refreshMorningSales() {
      this.$emit('refresh-morning-sales');
    },
  },
});
</script>
<style scoped>
.refresh-error-message {
  margin-top: 10px;
  color: red;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.refresh-message {
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  white-space: pre-wrap;
}

.button-error {
  background-color: red;
  border-color: red;
  color: white;
}
</style>
