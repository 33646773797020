<template>
  <div class="flex flex-col bg-gray-50">
    <div class="text-center py-2 bg-gray-300">
      Ventes du {{ selectedDate }}
    </div>

    <div v-if="isLoading" class="h-96 flex items-center justify-center text-3xl text-gray-400">
      <ClockIcon size="4x" class="animate-spin" />
    </div>

    <div v-else-if="!productPrevisions.length" class="h-96 flex items-center justify-center text-2xl text-gray-400">
      <pre>Aucune donnée</pre>
    </div>

    <table v-else-if="productPrevisions.length" class="table-fixed product-table w-full">
      <thead class="sticky top-0 h-12 flex-1 items-center text-center py-2 bg-gray-300">
        <tr>
          <th class="bg-gray-400">
            Qté<br>n-1
          </th>
          <th>Qté<br>totale</th>
          <th>Qté matin<br>(avant 13h)</th>
          <th class="whitespace-nowrap">
            Qté<br>après-midi
          </th>
          <th class="bg-gray-400">
            50%<br>à l'unité
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(product, i) of productPrevisions"
          :key="product.id"
          :class="{'bg-gray-200': isCurrentLineIndex(i) }"
          @mouseover="handleLineHover(i)"
        >
          <td
            class="text-center bg-gray-100"
            :class="{'bg-gray-200': isCurrentLineIndex(i) }"
          >
            <span>{{ quantity(product, 'lastYearSoldQuantity') }}</span>
          </td>
          <td class="text-center">
            <span>{{ quantity(product, 'lastWeekSoldQuantity') }}</span>
          </td>
          <td class="text-center">
            <span>{{ quantity(product, 'lastWeekMorningSoldQuantity') }}</span>
          </td>
          <td class="text-center">
            <span>{{ quantity(product, 'lastWeekAfternoonSoldQuantity') }}</span>
          </td>
          <td
            class="text-center bg-gray-100"
            :class="{'bg-gray-200': isCurrentLineIndex(i) }"
          >
            <span>{{ quantity(product, 'lastWeekDiscountSoldQuantity') }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import { ClockIcon } from '@vue-hero-icons/outline';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

dayjs.locale('fr');

export default Vue.extend({
  name: 'LastWeekTable',

  components: { ClockIcon },

  props: {
    productPrevisions: { type: Array, default: () => ([]) },
    currentLineHovered: { type: Number, required: true, default: 0 },
    isLoading: { type: Boolean, required: true, default: false },
  },

  computed: {
    selectedDate() {
      if (this.$store.getters.selectedDate === '') return ('-');
      const tempDate = dayjs(this.$store.getters.selectedDate).subtract(1, 'week');
      return dayjs(tempDate).format('dddd DD MMMM YYYY');
    },
  },
  methods: {
    handleLineHover(lineNumber) {
      this.$emit('line-hover', lineNumber);
    },
    isCurrentLineIndex(lineNumber) {
      return lineNumber === this.currentLineHovered;
    },
    quantity(product, property) {
      const plates = product.quantityPerPlate > 1 ? product[property] / product.quantityPerPlate : 0;
      const rest = plates ? product[property] % product.quantityPerPlate : 0;
      const roundedPlates = rest ? Math.floor(plates) + 1 : plates;

      if (product.quantityPerPlate > 1 && product.family !== 'Plaques') {
        return `${roundedPlates} (${product[property]})`;
      }
      return product[property];
    },
  },
});
</script>
