<template>
  <div
    class="weather-wrapper">
    <div
      class="weather-item"
      v-if="weather && !weatherLoading"
    >
      <div class="flex row justify-center items-center">
        <div>Min: {{weather.minTemperature}}°</div>
        <div>
          <img :src="urlIconTempMax" alt="Icon météo">
        </div>
        <div>Max: {{weather.maxTemperature}}°</div>
      </div>
      <div>
        {{weatherDescription}}
      </div>
    </div>
    <div
      class="weather-item weather-item--unavailable"
      v-if="!weather && !weatherLoading"
    >
      <span class="weather-item--unavailable">Météo indisponible</span>
    </div>
    <div
      class="weather-item--loading"
      v-if="weatherLoading"
    ><Spinner />
    </div>
  </div>
</template>
<script>
import Weather from '@/entities/Weather';
import Spinner from '@/components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  name: 'WeatherWidget',
  props: {
    weather: { type: Weather, default: null },
    weatherLoading: { type: Boolean, required: true },
  },
  computed: {
    urlIconTempMax() {
      return `https://openweathermap.org/img/wn/${this.weather?.weatherIcon}.png`;
    },
    weatherDescription() {
      return this.weather?.weatherDescription.charAt(0).toUpperCase() + this.weather?.weatherDescription.slice(1);
    },
  },
};
</script>
<style scoped>
.weather-item{
  color: #ffffff;
  font-weight: bold;
  background-color: rgb(117, 145, 187);  padding-bottom: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
}
.weather-wrapper{
  height: 76px;
}
.weather-item--unavailable{
  margin-bottom: 20px;
}
.weather-item--loading{
  background-color: rgb(117, 145, 187);  padding-bottom: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
</style>
