



















































import Vue from 'vue';
import services from '@/services/server';
import StorePrevisionsHeaderGroup from '@/components/StorePrevisionsHeaderGroup.vue';
import ProductPrevisionsDataGroup from '@/components/ProductPrevisionsDataGroup.vue';
import ProductButtons from '@/components/ProductButtons.vue';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import type ProductPrevision from '@/entities/ProductPrevision';
import StorePrevision from '@/entities/StorePrevision';
// eslint-disable-next-line import/extensions, import/no-unresolved
import { ToastID } from 'vue-toastification/dist/types/src/types';
import type { WeatherList } from '@/services/server/weather-service/types/weather';

dayjs.extend(isSameOrBefore);

export default Vue.extend({
  name: 'UserCommonPage',
  components: {
    StorePrevisionsHeaderGroup,
    ProductPrevisionsDataGroup,
    ProductButtons,
  },
  data():
    {
      loading: boolean;
      shop: string | null;
      storePrevision: StorePrevision | null;
      productPrevisions: ProductPrevision[];
      previsionChangeTimeoutId: number | null;
      previsionChangeToastId: ToastID | null;
      morningSalesChangeTimeoutId: number | null;
      morningSalesChangeToastId: ToastID | null;
      refreshLoading: boolean;
      weatherLoading: boolean;
      weatherList: WeatherList | null;
      currentDate: Date;
      timeToTimerInMinutes: number;
      timerInterval: any;
      refreshErrorMessages: string;
      } {
    return {
      loading: false,
      shop: null,
      storePrevision: null,
      productPrevisions: [],
      previsionChangeTimeoutId: null,
      previsionChangeToastId: 0,
      morningSalesChangeTimeoutId: null,
      morningSalesChangeToastId: 0,
      refreshLoading: false,
      refreshErrorMessages: '',
      weatherLoading: false,
      weatherList: {} as WeatherList,
      currentDate: new Date(),
      timeToTimerInMinutes: 0,
      timerInterval: null,
    };
  },
  computed: {
    isPreparateurPage(): boolean {
      return this.$route.name === 'PreparateurPage';
    },
    isBoulangerPage(): boolean {
      return this.$route.name === 'BoulangerPage';
    },
    shopId(): string {
      return this.$store.getters['shopAuth/authenticatedShopId'];
    },
    selectedDate(): string {
      return this.$store.getters.selectedDate;
    },
    productPrevisionsPreparateur(): ProductPrevision[] {
      return this.productPrevisions.filter((product) => product.productSource === 'PREPARATION');
    },
    productPrevisionsBoulanger(): ProductPrevision[] {
      return this.productPrevisions.filter((product) => product.productSource === 'BAKERY');
    },
    productPrevisionsForCurrentGroup(): ProductPrevision[] {
      if (this.isPreparateurPage) {
        return this.productPrevisionsPreparateur;
      }
      if (this.isBoulangerPage) {
        return this.productPrevisionsBoulanger;
      }
      return this.productPrevisions;
    },
    isAsyncOperationInProgress(): boolean {
      return this.$store.getters.isAsyncOperationInProgress;
    },
    hasExpectedRevenue(): boolean {
      return !!this.storePrevision?.expectedRevenue;
    },
    isSendingExpectedRevenue(): boolean {
      return this.$store.getters['storePrevisions/isSendingExpectedRevenue'];
    },
    isValidatingPrevisions(): boolean {
      return this.$store.getters['storePrevisions/isValidatingPrevisions'];
    },
    isPrevisionValidated(): boolean {
      return this.$store.getters['storePrevisions/isPrevisionValidated'];
    },
    isSendingMorningRevenue(): boolean {
      return this.$store.getters['storePrevisions/isSendingMorningRevenue'];
    },
    isValidatingMidday(): boolean {
      return this.$store.getters['storePrevisions/isValidatingMidday'];
    },
    isMiddayValidated(): boolean {
      return this.$store.getters['storePrevisions/isMiddayValidated'];
    },
    isOutOfAllowedPrevisionDatesRange(): boolean {
      const maxDate = dayjs().add(6, 'days');
      const minDate = dayjs().add(1, 'days');
      return dayjs(this.selectedDate).isSameOrBefore(minDate) || dayjs(this.selectedDate).isAfter(maxDate);
    },
    isOutOfAllowedMiddayDatesRange(): boolean {
      return !dayjs(this.selectedDate).isSame(dayjs(), 'day');
    },
    shouldDisableExpectedRevenueValidation(): boolean {
      return this.isOutOfAllowedPrevisionDatesRange
        || this.isPrevisionValidated
        || this.isAsyncOperationInProgress;
    },
    shouldDisablePrevisionTable(): boolean {
      return this.isOutOfAllowedPrevisionDatesRange
        || !this.hasExpectedRevenue
        || this.isSendingExpectedRevenue
        || this.isValidatingPrevisions
        || this.isPrevisionValidated;
    },
    shouldDisablePrevisionValidation(): boolean {
      return this.isOutOfAllowedPrevisionDatesRange
        || !this.hasExpectedRevenue
        || this.isAsyncOperationInProgress;
    },
    shouldDisableMiddayRevenue(): boolean {
      return this.isOutOfAllowedMiddayDatesRange
        || this.isMiddayValidated
        || this.isAsyncOperationInProgress;
    },
    shouldDisableMiddayValidation(): boolean {
      return this.isOutOfAllowedMiddayDatesRange
        || !this.isPrevisionValidated
        || this.isAsyncOperationInProgress;
    },
    shouldDisableMiddayTable(): boolean {
      return this.isOutOfAllowedMiddayDatesRange
        || this.isSendingMorningRevenue
        || this.isValidatingMidday
        || this.isMiddayValidated;
    },
    bootUpdateEnabled() {
      // TODO : Time range for auto refresh 13h30 - 14h30
      const currentTimeInMinutes = (this.currentDate.getHours() * 60) + this.currentDate.getMinutes();
      const timeStartValideInMinutes = (12 * 60) + 40;
      const timeEndValideInMinutes = (14 * 60) + 30;
      const currentTimeInValidRage = currentTimeInMinutes >= timeStartValideInMinutes
        && currentTimeInMinutes <= timeEndValideInMinutes;

      const selectDateDay = new Date(this.selectedDate).getDate();
      const currentDateDay = new Date().getDate();
      const currentYear = new Date().getFullYear();
      const selectDateYear = new Date(this.selectedDate).getFullYear();
      const isDateSelectedToday = selectDateDay === currentDateDay && currentYear === selectDateYear;

      if (isDateSelectedToday && currentTimeInValidRage) {
        return true;
      }
      return false;
    },
  },
  watch: {
    async selectedDate() {
      await this.fetchData();
      if (this.bootUpdateEnabled) {
        await this.refreshMorningSales();
      }
      if (this.selectDateIsToday()) {
        this.createTimerFromCurrentTime();
      }
      if (!this.selectDateIsToday()) {
        console.log('%cTimer Destroyed!!!', 'color: orange');
        clearInterval(this.timerInterval);
      }
    },
    async timeToTimerInMinutes() {
      const timeValideMinutes = (12 * 60) + 39;
      if (this.timeToTimerInMinutes > timeValideMinutes + 1) {
        clearInterval(this.timerInterval);
      }
      if (this.timeToTimerInMinutes === timeValideMinutes) {
        await this.refreshMorningSales();
      }
    },
  },
  async mounted() {
    this.createTimerFromCurrentTime();
    await this.fetchData();
    if (this.bootUpdateEnabled) {
      await this.refreshMorningSales();
    }
  },

  beforeDestroy() {
    clearInterval(this.timerInterval);
    console.log('%cTimer Destroyed!!!', 'color: orange');
  },

  methods: {
    handlePrevisionsRegenerated() {
      this.fetchData();
    },

    createTimerFromCurrentTime() {
      console.log('%cStart Function Create Timer!', 'color: orange');
      console.log(this.currentDate.getHours(), this.currentDate.getMinutes(), 'This Current Time!');
      const currentTimeInMinutes = (this.currentDate.getHours() * 60) + this.currentDate.getMinutes();
      const timeValideMinutes = (12 * 60) + 39;
      if (currentTimeInMinutes >= timeValideMinutes || !this.selectDateIsToday()) {
        console.log('%cTIMER NOT CREATED, currentTimeInMinutes >= timeValideMinutes', 'color: red');
        return;
      }
      console.log('%cTimer Created!!!', 'color: orange');
      this.timerInterval = setInterval(() => {
        const currentDate = new Date();
        this.timeToTimerInMinutes = (currentDate.getHours() * 60) + currentDate.getMinutes();
      }, 60000);
    },

    selectDateIsToday() {
      const selectDateDay = new Date(this.selectedDate).getDate();
      const currentDateDay = new Date().getDate();
      const currentYear = new Date().getFullYear();
      const selectDateYear = new Date(this.selectedDate).getFullYear();
      return selectDateDay === currentDateDay && currentYear === selectDateYear;
    },

    async refreshMorningSales() {
      this.refreshLoading = true;
      this.refreshErrorMessages = '';
      try {
        await services.refreshMorningSalesService.refreshMorningSales(this.shopId, this.selectedDate);
        await services.refreshMorningSalesService.refreshMorningTurnover(this.shopId, this.selectedDate);
        await this.fetchData({ withWeather: false });
        await this.fetchProductData();
      } catch (e) {
        console.log(e);
        console.error(e.message);
        this.refreshErrorMessages = 'Une erreur est survenue lors de la mise à jour des ventes du matin, veuillez réessayer...';
      } finally {
        this.refreshLoading = false;
      }
    },

    async fetchProductData() {
      if (this.selectedDate) {
        const products = await services.productPrevisionService.getProductPrevisions(this.shopId, this.selectedDate);
        return products;
      }
      return [];
    },

    async fetchData({ withWeather } = { withWeather: true }) {
      if (this.selectedDate) {
        if (withWeather) {
          this.weatherLoading = true;
          await this.getWeather();
        }
        this.storePrevision = await services.storePrevisionService.getStorePrevisions(this.shopId, this.selectedDate);
        this.$store.dispatch('storePrevisions/setPrevisionValidationStatus', this.storePrevision?.isPrevisionSaved);
        this.$store.dispatch('storePrevisions/setMiddayValidationStatus', this.storePrevision?.isMidDayCheckInSaved);

        this.productPrevisions = await this.fetchProductData();
        await this.$store.dispatch('storePrevisions/reCalculateRevenue', this.productPrevisions);
      }
    },

    async getWeather() {
      this.weatherLoading = true;
      this.weatherList = await services.weatherService.getWeather(this.shopId, this.selectedDate);
      this.weatherLoading = false;
    },

    async handleExpectedRevenueChange(value) {
      this.$store.dispatch('storePrevisions/sendingExpectedRevenue', true);
      try {
        this.productPrevisions = await services.productPrevisionService
          .initStoreAndGetProductPrevisionsWithExpectedRevenue(
            this.shopId, this.selectedDate, Number(value),
          );
        await this.$store.dispatch('storePrevisions/reCalculateRevenue', this.productPrevisions);
      } catch (error: any) {
        if (error?.response?.data?.status === 'VALIDATION_ERROR') {
          // TODO: replace the alert with a proper toast system
          window.alert('Erreur de validation:\nL\' "Ambition CA" doit être un chiffre entier');
        }
      }
      this.$store.dispatch('storePrevisions/sendingExpectedRevenue', false);
    },

    async triggerDebouncedPrevisionChange() {
      this.$store.dispatch('productPrevisions/sendingPrevisions', true);
      if (this.previsionChangeTimeoutId) {
        clearTimeout(this.previsionChangeTimeoutId);
      }
      if (!this.previsionChangeToastId && this.previsionChangeToastId !== 0) {
        this.previsionChangeToastId = this.$toast.info('Sauvegarde des Prévisions en cours...');
      }
      this.previsionChangeTimeoutId = setTimeout(async () => {
        try {
          await services.productPrevisionService.sendProductPlannedAndOpeningQuantities(
            this.shopId, this.selectedDate, this.productPrevisions,
          );
        } catch (error: any) {
          if (error?.response?.data?.status === 'VALIDATION_ERROR') {
            // TODO: replace the alert with a proper toast system
            window.alert(`Erreur de validation:
La "Prévisions magasin" ou "Rayon de 8h30" doit être
un chiffre entier, positif ou non-égal à zéro`);
          }
          this.productPrevisions = await this.fetchProductData();
        }
        await this.$store.dispatch('storePrevisions/reCalculateRevenue', this.productPrevisions);
        await this.$store.dispatch('productPrevisions/sendingPrevisions', false);
        if (this.previsionChangeToastId !== null) {
          this.$toast.dismiss(this.previsionChangeToastId);
        }
        this.previsionChangeToastId = null;
      }, 2000);
      await this.$store.dispatch('storePrevisions/reCalculateRevenue', this.productPrevisions);
    },

    async openPdfInNewTab(url) {
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.click();
    },

    async handleDownloadPreparateurMainSheet() {
      const url = await services.storePrevisionService.getDownloadPreparateurMainSheetUrl(
        this.shopId, this.selectedDate,
      );
      this.openPdfInNewTab(url);
    },
    async handleDownloadBoulangerMainSheet() {
      const url = await services.storePrevisionService.getDownloadBoulangerMainSheetUrl(
        this.shopId, this.selectedDate,
      );
      this.openPdfInNewTab(url);
    },

    async handleDownloadPreparateurRecapSheet() {
      const url = await services.storePrevisionService.getDownloadPreparateurRecapSheetUrl(
        this.shopId, this.selectedDate,
      );
      this.openPdfInNewTab(url);
    },
    async handleDownloadBoulangerRecapSheet() {
      const url = await services.storePrevisionService.getDownloadBoulangerRecapSheetUrl(
        this.shopId, this.selectedDate,
      );
      this.openPdfInNewTab(url);
    },

    async handleDownloadPreparateurSupportStockSheet() {
      const url = await services.storePrevisionService.getDownloadPreparateurSupportStockSheetUrl(
        this.shopId, this.selectedDate,
      );
      this.openPdfInNewTab(url);
    },
    async handleDownloadBoulangerSupportStockSheet() {
      const url = await services.storePrevisionService.getDownloadBoulangerSupportStockSheetUrl(
        this.shopId, this.selectedDate,
      );
      this.openPdfInNewTab(url);
    },

    async handlePrevisionsValidation() {
      await this.$store.dispatch('storePrevisions/validatingPrevisions', true);
      try {
        await this.validateAndClosePrevision();
      } catch (error: any) {
        window.alert(error?.response?.data?.status);
        window.alert(error?.response?.data?.message); // TODO: replace the alert with a proper toast system
      }
      await this.$store.dispatch('storePrevisions/validatingPrevisions', false);
    },

    async handlePrevisionsEdit() {
      await this.$store.dispatch('storePrevisions/validatingPrevisions', true);
      try {
        await this.editPrevision();
      } catch (error: any) {
        window.alert(error?.response?.data?.message); // TODO: replace the alert with a proper toast system
      }
      await this.$store.dispatch('storePrevisions/validatingPrevisions', false);
    },

    async validateAndClosePrevision() {
      this.storePrevision = await services.storePrevisionService.validateAndClosePrevisions(
        this.shopId, this.selectedDate,
      );
      this.$store.dispatch('storePrevisions/setPrevisionValidationStatus', this.storePrevision?.isPrevisionSaved);
    },

    async editPrevision() {
      this.storePrevision = await services.storePrevisionService.openPrevisions(
        this.shopId, this.selectedDate,
      );
      this.$store.dispatch('storePrevisions/setPrevisionValidationStatus', this.storePrevision?.isPrevisionSaved);
    },

    async handleMorningRevenueChange(value) {
      this.$store.dispatch('storePrevisions/sendingMorningRevenue', true);
      const backupStorePrevision: StorePrevision | null = this.storePrevision ? { ...this.storePrevision } : null;
      try {
        this.storePrevision = await services.storePrevisionService.initializeStorePrevisionsWithMorningRevenue(
          this.shopId, this.selectedDate, value,
        );
      } catch (error: any) {
        if (error?.response?.data?.status === 'VALIDATION_ERROR') {
          // TODO: replace the alert with a proper toast system
          window.alert('Erreur de validation:\nLe "CA Matin" doit être un chiffre entier, positif ou égal à zéro');
        }
        this.storePrevision = backupStorePrevision;
      }
      this.$store.dispatch('storePrevisions/sendingMorningRevenue', false);
    },

    async triggerDebouncedMorningSalesChange() {
      this.$store.dispatch('productPrevisions/sendingMorningSales', true);
      if (this.morningSalesChangeTimeoutId) {
        clearTimeout(this.morningSalesChangeTimeoutId);
      }
      if (!this.morningSalesChangeToastId && this.morningSalesChangeToastId !== 0) {
        this.morningSalesChangeToastId = this.$toast.info('Sauvegarde des Ventes du matin en cours...');
      }
      this.morningSalesChangeTimeoutId = setTimeout(async () => {
        try {
          await services.productPrevisionService.sendProductMorningSales(
            this.shopId, this.selectedDate, this.productPrevisions,
          );
        } catch (error: any) {
          if (error?.response?.data?.status === 'VALIDATION_ERROR') {
            // TODO: replace the alert with a proper toast system
            window.alert(`Erreur de validation:
Les "Ventes du matin", "Stock" ou "Relances" doivent être
un chiffre, positif ou égal à zéro`);
          }
          this.productPrevisions = await this.fetchProductData();
        }
        await this.$store.dispatch('productPrevisions/sendingMorningSales', false);
        if (this.morningSalesChangeToastId !== null) {
          this.$toast.dismiss(this.morningSalesChangeToastId);
        }
        this.morningSalesChangeToastId = null;
      }, 2000);
    },

    async handleMiddayValidation() {
      await this.$store.dispatch('storePrevisions/validatingMidday', true);
      try {
        await this.validateAndCloseMidday();
      } catch (error: any) {
        window.alert(error?.response?.data?.status);
        window.alert(error?.response?.data?.message); // TODO: replace the alert with a proper toast system
      }
      await this.$store.dispatch('storePrevisions/validatingMidday', false);
    },

    async handleMiddayEdit() {
      await this.$store.dispatch('storePrevisions/validatingMidday', true);
      try {
        await this.editMidday();
      } catch (error: any) {
        window.alert(error?.response?.data?.message); // TODO: replace the alert with a proper toast system
      }
      await this.$store.dispatch('storePrevisions/validatingMidday', false);
    },

    async validateAndCloseMidday() {
      this.storePrevision = await services.storePrevisionService.validateAndCloseMidday(
        this.shopId, this.selectedDate,
      );
      this.$store.dispatch('storePrevisions/setMiddayValidationStatus', this.storePrevision?.isMidDayCheckInSaved);
    },

    async editMidday(): Promise<void> {
      this.storePrevision = await services.storePrevisionService.openMidday(
        this.shopId, this.selectedDate,
      );
      this.$store.dispatch('storePrevisions/setMiddayValidationStatus', this.storePrevision?.isMidDayCheckInSaved);
    },
  },
});
