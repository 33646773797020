const shopsAllowedToRefreshMiddayCheckin = ['5392', '6314', '6303'];

class PermissionMiddayRefreshService {
  private readonly shopsAllowedToRefreshMiddayCheckin: string[];
  private readonly optionActive: boolean = false;

  constructor(shopAllowed: string[]) {
    this.shopsAllowedToRefreshMiddayCheckin = shopAllowed;
  }

  static verifyShopsCodeIsNumber(shopsCode: string[]): boolean {
    // eslint-disable-next-line no-restricted-globals
    return shopsCode.every((shopCode) => !isNaN(Number(shopCode)));
  }

  checkIfShopIsAllowedToRefreshMiddayCheckin(shopId: string | number): boolean {
    const shopCodeIsValid = PermissionMiddayRefreshService
      .verifyShopsCodeIsNumber(this.shopsAllowedToRefreshMiddayCheckin);

    if (!shopCodeIsValid) {
      throw new Error('Shop code is not a number');
    }

    if (!this.optionActive) {
      return true;
    }
    return this.shopsAllowedToRefreshMiddayCheckin.includes(shopId.toString());
  }
}

const permissionMiddayRefreshService = new PermissionMiddayRefreshService(shopsAllowedToRefreshMiddayCheckin);

export default permissionMiddayRefreshService;
